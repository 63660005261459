<script>
import MasterDetail from "@/components/master-detail.vue";
import { mapGetters } from "vuex";
import { isObject } from 'lodash';

export default {
  components: { MasterDetail },
  computed: {
    ...mapGetters(["clientId"]),
    cols() {
      return [
        {
          key: "atividade",
          name: "Atividade",
          type: this.$fieldTypes.TEXT,
        },
      ];
    },
    customResource() {
      const notify = (msg) => this.$notify({
        group: "geral",
        duration: 5000,
        type: "alert",
        title: "Mockado",
        text: msg,
      });
      return {
        async get(...args) {
          console.log('Atividade customResource GET', isObject(args) ? { ...args } : args);
          notify('GET não implementado');
          return [];
        },
        async save(...args) {
          console.log('Atividade customResource SAVE', isObject(args) ? { ...args } : args);
          notify('SAVE não implementado');
          return {};
        },
        async patch(...args) {
          console.log('Atividade customResource PATCH', isObject(args) ? { ...args } : args);
          notify('PATCH não implementado');
          return {};
        },
        async delete(...args) {
          console.log('Atividade customResource DELETE', isObject(args) ? { ...args } : args);
          notify('DELETE não implementado');
          return {};
        },
      }
    }
  },
  methods: {
    doImport() {
      window.alert("TO DO Implementar");
    }
  }
};
</script>

<template>
  <MasterDetail
    :cols="cols"
    :customResource="customResource"
    formTitle="Inclusão de Atividade"
    :hasFilter="false"
    :hasExportCSV="false"
    newButtonText="Adicionar atividade"
    serverPagination
  >
    <div class="d-flex">
      <v-spacer></v-spacer>
      <div
        aria-label="importButton"
        class="table-v-action-button mr-3 pt-1"
        @click="doImport()"
      >
        <v-icon>mdi-upload-box</v-icon> Importações
      </div>
    </div>
  </MasterDetail>
</template>
